import './splash.css';
import overview from '../../assets/bifrustOverview.png';
import dataInsightsIcon from '../../assets/carbon_data-vis.svg';
import dataConnectivityIcon from '../../assets/carbon_cloud-data-connectivity.svg';
import dataReportIcon from '../../assets/bi_clipboard-data.svg';
import dataTransmitIcon from '../../assets/carbon_data-transmit.svg';
import { Grid, Button } from 'semantic-ui-react';
import React from 'react';

function Splash() {
    return (
        <div className="splashComponent"> 
            <div className="splashTopSection">
                <Grid container reversed='computer' centered verticalAlign='middle' columns={2}>
                    <Grid.Column className='noPadding' mobile={16} tablet={10} computer={11}>
                        <div className="imgSplash">
                            <div className='splashHeadingMobile'>Connect Your Data</div>
                            <img src={overview} alt="Appimage" />
                        </div>
                    </Grid.Column>
                    <Grid.Column className='noPadding' mobile={16} tablet={6} computer={5}>
                        <div className="splashTextSection">
                            <div className='splashHeading'>Control Your Data</div>
                            <div className='splashText'>Manage cloud and local data with ease. Get visibility in to storage capacity and consumption. Transmit data between local and cloud storage platforms. </div>
                            <div className='splashButtonContainer'>
                                <Button className='large splashButton'> Signup
                                    {/* <Button.Content visibile>Sign up</Button.Content>
                                    <Button.Content hidden>Free</Button.Content> */}
                                </Button>
                                </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
            <div className='splashBottomSection'>
                <Grid container centered doubling columns={4}>
                    <Grid.Column width={4} >
                        <div className='splashBottomItem'>
                            <div><img src={dataInsightsIcon} className="splashBottomItemIcon" alt="dataInsightsIcon" /></div>
                            <div className='splashBottomItemHeading'>Data Insights</div>
                            <div className='splashBottomItemText'>Easily determine which files and folders are consuming space across your cloud, network and local storage platforms.</div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <div className='slashBottomItem'>
                            <div><img src={dataConnectivityIcon} className="splashBottomItemIcon" alt="dataInsightsIcon" /></div>
                            <div className='splashBottomItemHeading'>Connectivity</div>
                            <div className='splashBottomItemText'>Connect local & cloud storage platforms such as Microsoft 365, OneDrive, Live, Google Drive (Personal & Enterprise), Box, SMB/Network shares </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <div className='splashBottomItem'>
                            <div><img src={dataReportIcon} className="splashBottomItemIcon" alt="dataInsightsIcon" /></div>
                            <div className='splashBottomItemHeading'>Reporting</div>
                            <div className='splashBottomItemText'>Generate detailed item level report on the connected storage platforms to plan large scale data migrations, orchestration and management.</div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <div className='splashBottomItem'>
                            <div><img src={dataTransmitIcon} className="splashBottomItemIcon" alt="dataInsightsIcon" /></div>
                            <div className='splashBottomItemHeading'>Transmit</div>
                            <div className='splashBottomItemText'>Transmit data across storage platforms to aid in migration, data orchestration and enrichment</div>
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
        </div>
    );
}

export default Splash;