import './search.css';
// import box from '../../assets/box.svg';
import { Grid } from 'semantic-ui-react';
import React from 'react';


function Search() {
    return (
        <div className="searchComponent"> 
            <div className="searchContainer">   
                <Grid  verticalAlign='middle' centered padded className='searchGrid' columns={2}>
                    <Grid.Row stretched className='removeAll'>
                        <Grid.Column horizontally padded className='searchCard showborder' mobile={14} tablet={12} computer={6}>
                             <div className='searchCardContainer'>
                                <Grid columns={2} className='setHeight removeAll'>
                                    <Grid.Row stretched className='setHeight removeAll ASC'>
                                        <Grid.Column className='showBorder setHeight removeAll'>
                                            <div id='ASC' className='searchCardHeading showborder verticalCenter'>Advanced Searching Capability</div>
                                        </Grid.Column>
                                        <Grid.Column className='showBorder setHeight removeAll'>
                                            <div className='searchCardText showborder verticalCenter'>Search through your folders and files with advanced capabilities to locate hard to find data regardless of it being on local or cloud storage platforms.</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                        <Grid.Column className='searchCard ' mobile={14} tablet={12} computer={3} >
                        <div className='searchCardContainer'>
                                <Grid columns={2} className='showBorder removeAll setHeight'>
                                    <Grid.Row stretched className='setHeight removeAll'>
                                        <Grid.Column width={16} className='removeAll'>
                                            <div id='report' className='searchCardHeading showborder centerText removeAll'>Report</div>
                                            <div className='searchCardText showborder'>Gain insights on storage and platform usage, data growth and helps you plan your next data migration.</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row stretched className='removePadding'>
                        <Grid.Column className='searchCard' mobile={14} tablet={12} computer={3}>
                        <div className='searchCardContainer'>
                                <Grid columns={2} className='showBorder removeAll setHeight'>
                                    <Grid.Row stretched>
                                        <Grid.Column width={16} className='removeAll'>
                                            <div id='savedSearch' className='searchCardHeading showborder centerText removeAll'>Search Tags</div>
                                            <div className='searchCardText showborder removeAll'>Assign tags to files, folders and users to quickly retrieve storage insights quickly.</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                        <Grid.Column className='searchCard showBorder' mobile={14} tablet={12} computer={6}>
                            <div className='searchCardContainer'>
                                <Grid columns={2} className='setHeight removeAll'>
                                    <Grid.Row stretched className='setHeight removeAll'>
                                        <Grid.Column className='showBorder setHeight removeAll fixWidth'>
                                            <div id='tryingToMigrate' className='searchCardHeading showborder verticalCenter oneWordMe'>Looking To Migrate?</div>
                                        </Grid.Column>
                                        <Grid.Column className='showBorder setHeight removeAll expandWidth'>
                                            <div className='searchCardText showborder verticalCenter'>Built for transferring between storage platforms with ease. Packed with features to help you plan and migrate users, folders and files.</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    );
}

export default Search;