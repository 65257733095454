import './navbar.css';
import logo from '../../assets/bifrustLogo.svg';
// import signinImg from '../../assets/signin.svg';
import { Button, Dropdown, Grid, Icon, Input } from 'semantic-ui-react';
import React, { useState } from 'react';
import authUser from '../auth/authUser';

//const authURL="https://bifrust.com/login";

// export default function App(){}


function Navbar() {

    const [scrollState, setScrollState] = useState(false);
    const changeNavbarBG =()=>{
        const scrollValue=document.documentElement.scrollTop;
        if (scrollValue>60) {
            setScrollState(true);
            console.log (scrollValue)
        }
        else {
            setScrollState(false);
        }
    }

    window.addEventListener('scroll',changeNavbarBG)

   // const userRef = useRef(); 
   // const errRef = useRef();
    const {http} = authUser();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
   // const [errMsg, setErrMsg] = useState('');
   // const [success, setSuccess] = useState(false);

   function redirectToPost(url, data) {
    // Create a form element
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;

    // Iterate over the data object and append hidden input fields
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = data[key];
            form.appendChild(hiddenField);
        }
    }

    // Append the form to the body and submit it
    document.body.appendChild(form);
    form.submit();
}
    const handleSubmit = event => {
        console.log('handleSubmit ran');
        event.preventDefault();
        console.log (email);
        console.log (password);
        // http.post('https://bifrust.com/login',{email:email,password:password}).then((res)=>{
        //     console.log(res.data);
        // })
        const url = 'https://bifrust.com/login'; // External URL
        const data = {
            email: email,
            password: password
        };

        redirectToPost(url, data);
    }


    

    return (
        <div className="navbar">
            <header>
                <div className="nav"> 
                    <div className={scrollState?"navScroll":"nav"}> 
                        <Grid verticalAlign='middle' centered columns={3}>
                            <Grid.Row className={scrollState?"rowScroll":""} stretched>
                                <Grid.Column width={2}>
                                    <div className='navLogo'><img className={scrollState?"navLogoImgScroll":"navLogo"} src={logo}  alt="logo" /></div>
                                </Grid.Column>
                                <Grid.Column verticalAlign='middle' width={8}>
                                        <ul className={scrollState?"navULScroll":""}>
                                            <li><a href="#d">Features</a></li>
                                            <li><a href="#d">Pricing</a></li>
                                            <li><a href="#d">Docs</a></li>
                                        </ul>
                                </Grid.Column>
                                <Grid.Column centered width={2}>
                                    <div className={scrollState?"loginButtonScroll":"loginButton"}>
                                        <Dropdown direction='left' icon='user' >
                                            <Dropdown.Menu  className="loginDropDown" onClick={e => e.stopPropagation()}>
                                                    <form onSubmit={handleSubmit} id='login-form' className='loginForm'>
                                                        <Input 
                                                            placeholder="Email" 
                                                            id="email" 
                                                            type="text" 
                                                            icon='user'
                                                            onChange={event => setEmail(event.target.value)}
                                                            name='email'
                                                            // value={email}
                                                            />
                                                        <Input 
                                                            placeholder="Password" 
                                                            icon='key' 
                                                            id="password" 
                                                            type="password" 
                                                            name='password'
                                                            onChange={event => setPassword(event.target.value)}
                                                        />
                                                        <Button className='submitButton' type='submit'>Login</Button>
                                                    </form>
                                                    <div className='bottomLinks'>
                                                        <div>Forgot password?</div>
                                                        <div>New Account</div>
                                                    </div>
                                                    
                                            </Dropdown.Menu>
                                        </Dropdown>
                                      
                                        <div className='hamburgerButton'>
                                            <Button>
                                                <Button.Content visible>
                                                    <Icon name='bars'/>
                                                </Button.Content>
                                            </Button>
                                        </div>  
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid> 
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Navbar;
